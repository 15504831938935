import axios from 'axios'
import qs from "qs";
import { config } from '../../config';

const state = {
  token: localStorage.getItem('token') || '',
  deliveryArticles: [],
  bin: undefined,
  projectId: undefined,
};

const mutations = {
  setDeliveryArticles(state, deliveryArticles) {
    state.deliveryArticles = deliveryArticles
  },
  setBin(state, bin) {
    state.bin = bin
  },
  setProjectId(state, projectId) {
    state.projectId = projectId
  },
}

const actions = {
  getDeliveryArticles({ commit }, { orderId, deliveryNr }) {
    const token = localStorage.getItem('token')
    return new Promise((resolve, reject) => {
      const url = `${config.RAILS_API}/api/v1/app/operations/${orderId}/delivery/${deliveryNr}`;
      const options = {
        method: 'GET',
        params: {
          jwt_token: token,
        },
        url,
      };
      axios(options)
        .then(res => {
          const { articles, bin, projectId } = JSON.parse(JSON.stringify(res)).data;
          commit('setDeliveryArticles', articles);
          commit('setBin', bin);
          commit('setProjectId', projectId);
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  updateArticleStatus({ commit }, { orderId, deliveryNr, zid, sn, status }) {
    const jwt_token = localStorage.getItem('token');

    return new Promise((resolve, reject) => {
      const url = `${config.RAILS_API}/api/v1/app/operations/${orderId}/delivery/${deliveryNr}/${zid}/update_status`;
      const options = {
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify({
          jwt_token,
          status: status, // "booked" oder "notInPackage",
          sn: sn,
        }),
        url,
      };
      axios(options)
        .then(res => {
          const { articles, bin, projectId } = JSON.parse(JSON.stringify(res)).data;
          commit('setDeliveryArticles', articles);
          commit('setBin', bin);
          commit('setProjectId', projectId);
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  addWrongArticle({ commit }, { orderId, deliveryNr, templateId, sn, count }) {
    const jwt_token = localStorage.getItem('token');

    return new Promise((resolve, reject) => {
      const url = `${config.RAILS_API}/api/v1/app/operations/${orderId}/delivery/${deliveryNr}/wrong_article`;
      const options = {
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify({
          jwt_token, templateId, sn, count
        }),
        url,
      };
      axios(options)
        .then(res => {
          const { articles, bin, projectId } = JSON.parse(JSON.stringify(res)).data;
          commit('setDeliveryArticles', articles);
          commit('setBin', bin);
          commit('setProjectId', projectId);
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
};

const getters = {
  deliveryArticles: state => state.deliveryArticles,
  bin: state => state.bin,
  projectId: state => state.projectId,
};

export default {
  state,
  mutations,
  actions,
  getters,
}
