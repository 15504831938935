<template>
  <v-app>
    <v-content>
      <v-container fill-height class="primary" style="position: relative;">
        <v-layout align-center justify-center>
          <v-flex class="login-form text-xs-center">
            <div class="display-1 mb-3 white--text">Hawk</div>
            <v-card light>
              <v-card-text>
                <v-alert
                  :value="error"
                  type="warning"
                  dismissible
                  style="margin-bottom: 1rem;"
                >
                  Login failed.
                </v-alert>
                <v-form @submit="login">
                  <v-text-field
                    v-model.trim="email"
                    :error-messages="emailErrors"
                    light
                    prependIcon="email"
                    label="Email"
                    type="email"
                  />
                  <v-text-field
                    v-model.trim="password"
                    :error-messages="passwordErrors"
                    light
                    prependIcon="lock"
                    label="Password"
                    type="password"
                  />
                  <v-btn block="block" type="submit">Login</v-btn>
                </v-form>
              </v-card-text>
            </v-card>
            <div
              style="display: flex; justify-content: space-between; align-items: flex-end;"
            >
              <p class="mt-2 white--text">Bentomax GmbH</p>
              <p class="white--text">{{ api }}</p>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, email } from 'vuelidate/lib/validators'
import { config } from '@/config'
import sync from '@/sync/sync'

export default {
  name: 'Login',
  mixins: [validationMixin],

  validations: {
    email: { required, email },
    password: { required, minLength: minLength(6) },
  },

  data() {
    return {
      email: '',
      password: '',
      error: false,
      api: config.version,
    }
  },
  computed: {
    emailErrors() {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('Please check your email address')
      !this.$v.email.required &&
        errors.push('Please enter your email address')
      return errors
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.minLength &&
        errors.push('Your password must be at least 6 characters long')
      !this.$v.password.required &&
        errors.push('Please enter your password')
      return errors
    },
  },
  methods: {
    login(e) {
      e.preventDefault()
      this.$v.$touch()
      //
      // Sometimes we need to reset the database *hard*. We use the login form because
      // this is not something that should be obviously exposed to the user via UI.
      //
      if (this.email === 'datenbank_reset' && this.password === 'Ich weiß was ich tue!') {
          sync.resetDatabase()
      } else if (!this.$v.email.$error && !this.$v.password.$error) {
        const { email, password } = this
        /*this.$store
          .dispatch('login', { email, password })
          .then(() => {
            this.$store
              .dispatch('loginRails', { email, password })
              .then(() => {
                this.$router.push('/')
                // sync.start()
              })
              .catch(err => {
                this.error = !err.ok
              })
          })
          .catch(err => {
            this.error = !err.ok
          })*/
        this.$store
          .dispatch('loginRails', { email, password })
          .then(() => {
            this.$router.push('/')
            // sync.start()
          })
          .catch(err => {
            this.error = !err.ok
          })
      }
    },
  },
}
</script>

<style>
.bg-green {
  background-color: #28b414;
}
</style>
