<template>
  <div :id="uppyId">
    <div v-if="collection === 'thumbnail'" class="ThumbnailContainer">
      <button id="open-thumbnail-modal" class="button">Select file</button>
    </div>
    <div v-else class="DashboardContainer" style="padding: 0 1rem;"></div>
  </div>
</template>

<script>
import Uppy from '@uppy/core'
import Dashboard from '@uppy/dashboard'
import Webcam from '@uppy/webcam'
import XHRUpload from '@uppy/xhr-upload'
import { config } from '../config'

require('@uppy/core/dist/style.css')
require('@uppy/dashboard/dist/style.css')
require('@uppy/webcam/dist/style.css')

export default {
  props: {
    // modelClass: {
    //   type: String,
    //   required: true,
    // },
    // modelId: {
    //   type: String,
    //   required: true,
    // },
    collection: {
      type: String,
      default: '',
    },
    // endpoint: {
    //   type: String,
    //   required: false,
    //   default() {
    //     return '/upload'
    //   },
    // },
  },

  data() {
    return {}
  },

  computed: {
    uppyId() {
      return this.modelClass + '-' + this.modelId + '-' + this.collection
    },
  },

  mounted() {
    const uppy = Uppy({
      id: this.uppyId,
      autoProceed: false,
      // debug: true, will set console logs
      debug: false,
      restrictions: {
        maxFileSize: false,
        allowedFileTypes: ['image/*', 'application/pdf'],
      },
      meta: {
        jwt_token: this.$store.state.token,
      },
      onBeforeFileAdded: () => {
        // console.log(currentFile, files)
      },
      onBeforeUpload: (files, done) => {
        console.log(files, done)
      },
    })
    // if (this.collection === 'thumbnail') {
    //   uppy.use(Dashboard, {
    //     trigger: '#open-thumbnail-modal',
    //     metaFields: [
    //       {
    //         id: 'owner',
    //         name: 'Owner',
    //         placeholder: 'name of the photographer/owner',
    //       },
    //       {
    //         id: 'caption',
    //         name: 'Caption',
    //         placeholder: 'describe what the image is about',
    //       },
    //       { id: 'order', name: 'Order', placeholder: 'order' },
    //     ],
    //   })
    // } else {
    uppy.use(Dashboard, {
      inline: true,
      target: '.DashboardContainer',
      replaceTargetContent: true,
      note: 'Images and PDF only.',
      height: 250,
      maxHeight: 250,
      width: '100%',
      metaFields: [
        {
          id: 'owner',
          name: 'Owner',
          placeholder: 'name of the photographer/owner',
        },
        {
          id: 'caption',
          name: 'Caption',
          placeholder: 'describe what the image is about',
        },
        { id: 'order', name: 'Order', placeholder: 'order' },
      ],
    })

    uppy.use(Webcam, {
      onBeforeSnapshot: () => Promise.resolve(),
      onBeforeFileAdded: asd => {
        console.log(asd)
      },
      countdown: false,
      modes: ['picture'],
      mirror: false,
      facingMode: 'environment',
      target: Dashboard,
      locale: {},
      metaFields: ['file'],
    })
    // }
    uppy.use(XHRUpload, {
      endpoint: `${config.RAILS_API}/api/v1/app/operations/${this.$route.params.orderId}/upload`,
      // headers: {
      //   // 'Content-Type': 'multipart/form-data',
      //   // 'X-CSRF-TOKEN': this.$store.state.token,
      // },
      metaFields: ['jwt_token'],
      // params: this.$store.state.token,
      fieldName: 'file',
      formData: true,
    })
    uppy.on('complete', result => {
      console.log('successful files:', result.successful)
      console.log('failed files:', result.failed)
      this.$emit('complete')
    })
  },

  methods: {},
}
</script>

<style lang="scss">
.uppy-Dashboard-innerWrap .uppy-DashboardContent-bar,
.uppy-Dashboard-AddFilesPanel,
.uppy-DashboardItem-action,
.uppy-StatusBar-actions,
.uppy-StatusBar,
.uppy-Dashboard-poweredBy,
.uppy-StatusBar-content,
.uppy-StatusBar-progress,
.uppy-DashboardItem-previewInnerWrap:after {
  z-index: unset !important;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
  background: #1976d2 !important;
  &:hover {
    background: lighten(#1976d2, 10%) !important;
  }
}

.uppy-DashboardContent-addMore,
.uppy-DashboardContent-back,
.uppy-StatusBar-actionBtn {
  color: #1976d2 !important;
}

// @media screen (min-width: 600px) {
// }
</style>
