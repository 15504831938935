import axios from 'axios'
import { config } from '../../config'

const state = {
  status: '',
  token: localStorage.getItem('token') || '',
  user: {},
  operations: [],
  warehouses: [],
  articles: [], // Alle Artikel eines bestimmten Lagerplatzes
  projects: [], // Alle Projekte, in denen Materialeingang verbucht werden kann
  deliveryCount: 0,
  url: '',
}

const mutations = {
  setWarehouses(state, warehouses) {
    state.warehouses = warehouses
  },
  setArticles(state, articles) {
    state.articles = articles
  },
  setProjects(state, projects) {
    state.projects = projects
  },
  setDeliveryCount(state, deliveryCount) {
    state.deliveryCount = deliveryCount
  },
}

const actions = {
  getWarehouses({ commit }) {
    const token = localStorage.getItem('token')
    return new Promise((resolve, reject) => {
      const url = `${config.RAILS_API}/api/v1/app/warehouse`
      const options = {
        method: 'GET',
        params: {
          jwt_token: token,
        },
        url,
      }
      axios(options)
        .then(res => {
          const { warehouses } = JSON.parse(JSON.stringify(res)).data

          function compare(a, b) {
            if (a.label < b.label) return -1
            if (a.label > b.label) return 1
            return 0
          }
          for (let warehouse of warehouses) {
            warehouse.bins.sort(compare)
          }
          warehouses.sort(compare)
          commit('setWarehouses', warehouses)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  getArticles({ commit }, { warehouseId, binId, complete = false }) {
    if (!warehouseId || !binId) {
      console.log(
        "don't call getArticles, there is no warehouseId or binId in operation"
      )
      return []
    }
    const token = localStorage.getItem('token')
    return new Promise((resolve, reject) => {
      const url = `${config.RAILS_API}/api/v1/app/warehouse/${warehouseId}/${binId}`
      const options = {
        method: 'GET',
        params: {
          jwt_token: token,
          complete: complete,
        },
        url,
      }
      axios(options)
        .then(res => {
          // const data = JSON.parse(JSON.stringify(res)).data
          const { articles } = JSON.parse(JSON.stringify(res)).data
          commit('setArticles', articles)
          // resolve(res)
          resolve(articles)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  getProjects({ commit }) {
    const token = localStorage.getItem('token')
    return new Promise((resolve, reject) => {
      const url = `${config.RAILS_API}/api/v1/app/project`
      const options = {
        method: 'GET',
        params: {
          jwt_token: token,
        },
        url,
      }
      axios(options)
        .then(res => {
          const { projects, deliveryCount } = JSON.parse(
            JSON.stringify(res)
          ).data

          function compare(a, b) {
            if (a.name < b.name) return -1
            if (a.name > b.name) return 1
            return 0
          }
          projects.sort(compare)
          commit('setProjects', projects)
          commit('setDeliveryCount', deliveryCount)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  sendMaterial({ commit }, { projectId, materials}) {
    const jwt_token = localStorage.getItem('token') // eslint-disable-line

    return new Promise((resolve, reject) => {
      const url = `${config.RAILS_API}/api/v1/app/project/${projectId}/add_articles`
      const options = {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${jwt_token}`, // eslint-disable-line
        },
        data: materials,
        url,
      }
      axios(options)
        .then(() => {
          console.log('succesfully added material')
          resolve()
        })
        .catch(err => {
          reject(err.response.data.error)
        })
    })
  },
}

const getters = {
  warehouses: state => state.warehouses,
  articles: state => state.articles,
  projects: state => state.projects,
  deliveryCount: state => state.deliveryCount,
}

export default {
  state,
  mutations,
  actions,
  getters,
}
