import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'
import axios from 'axios'
import qs from 'qs'

import delivery from './modules/delivery'
import material from './modules/material'
import operation from './modules/operation'
import devices from './modules/devices'
import stations from './modules/stations'

import { config } from '../config'

Vue.use(Vuex)

const vuexLocalStorage = new VuexPersist({
  key: 'vuex', // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  reducer: state => ({
    token: state.token,
    operations: state.operations,
    url: state.url,
  }),
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
})

export default new Vuex.Store({
  plugins: [vuexLocalStorage.plugin],
  modules: {
    operation: operation,
    material: material,
    delivery: delivery,
    devices: devices,
    stations: stations,
  },
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user: {
      name: localStorage.getItem('user.name') || '',
      email: localStorage.getItem('user.email') || '',
    },
    url: '', // current navigation url
  },
  mutations: {
    auth_request(state) {
      state.status = 'loading'
    },
    auth_success(state, data) {
      state.status = 'success'
      state.token = data.token
      state.user = data.user
    },
    auth_error(state) {
      state.status = 'error'
    },
    logout(state) {
      state.status = ''
      state.token = ''
    },
    setUrl(state, url) {
      state.url = url
    },
  },
  actions: {
    /*login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        const url = `${config.API}/login`
        const options = {
          method: 'POST',
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
          },
          data: qs.stringify(user),
          url,
        }
        axios(options)
          .then(res => {
            const data = JSON.parse(JSON.stringify(res)).data
            if (data.ok) {
              const token = data.jwt_token
              const user = {
                name: data.name,
                email: data.email,
              }
              localStorage.setItem('token', token)
              localStorage.setItem('user.name', user.name)
              localStorage.setItem('user.email', user.email)
              axios.defaults.headers.common['Authorization'] = token
              commit('auth_success', {
                token,
                user,
              })
              resolve(res)
            } else {
              reject(data)
            }
          })
          .catch(err => {
            commit('auth_error')
            localStorage.removeItem('token')
            localStorage.removeItem('user.name')
            localStorage.removeItem('user.email')
            reject(err)
          })
      })
    },*/
    loginRails({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        const url = `${config.RAILS_API}/api/v1/login.json`
        const options = {
          method: 'POST',
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
          },
          data: qs.stringify(user),
          url,
        }
        axios(options)
          .then(res => {
            if (res && res.status === 200 && res.data) {
              const token = "token"
              const user_hash = res.data.user
              const name = `${user_hash.firstname} ${user_hash.lastname}`
              const user = {
                name: name,
                email: user_hash.email,
              }
              localStorage.setItem('token', token)
              localStorage.setItem('railsToken', token)
              localStorage.setItem('user.name', user.name)
              localStorage.setItem('user.email', user.email)
              axios.defaults.headers.common['Authorization'] = token
              commit('auth_success', {
                token,
                user,
              })
              resolve(res)
            } else {
              reject(res)
            }
          })
          .catch(err => {
            commit('auth_error')
            localStorage.removeItem('token')
            localStorage.removeItem('railsToken')
            localStorage.removeItem('user.name')
            localStorage.removeItem('user.email')
            reject(err)
          })
      })
    },
    logout({ commit }) {
      return new Promise(resolve => {
        commit('logout')
        localStorage.removeItem('token')
        localStorage.removeItem('railsToken')
        localStorage.removeItem('user.name')
        localStorage.removeItem('user.email')
        delete axios.defaults.headers.common['Authorization']
        resolve()
      })
    },
  },
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
  },
})
